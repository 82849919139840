@keyframes slideInFromBottom {
  from {
    transform: translateY(200%); /* Start completely off-screen */
    opacity: 1; /* Start invisible */
  }
  to {
    transform: translateY(0); /* End in the original position */
    opacity: 1; /* Fully visible */
  }
}

@keyframes slideInFromBottomWithScale {
  from {
    transform: translateY(200%) scale(0.45); /* Start off-screen with scaling */
    opacity: 0; /* Start invisible */
  }
  to {
    transform: translateY(0) scale(0.45); /* End at original position with scaling */
    opacity: 1; /* Fully visible */
  }
}

.container {
  position: relative; /* Establish positioning context for the phone */
  overflow: hidden; /* Clip content that overflows the container */
  width: 100%;
  height: 100%;
}

.report {
  position: absolute; /* Position the report relative to the container */
  bottom: 0; /* Align the bottom edge of the report to the bottom of the container */
  left: 0; /* Align to the left edge of the container */
  right: 0; /* Stretch to the right edge of the container */
  scale: 0.9; /* Maintain scaling */
  transform-origin: bottom; /* Ensure scaling occurs relative to the bottom edge */
  overflow: auto; /* Allow scrolling inside the report */
  max-height: 100%; /* Ensure it doesn't exceed container bounds */

  /* Animation properties */
  animation: slideInFromBottom 1.5s ease-out forwards;
  opacity: 1; /* Initially hidden */
  border-radius: var(--br-base) var(--br-base) 0 0;
  box-shadow:
    0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -1px 8px 2px rgba(10, 37, 64, 0.25);
}

.img {
  width: 100%;
  border-radius: var(--br-base);
}

.phone {
  position: absolute; /* Position relative to the container */
  bottom: -10%; /* Anchored to the bottom */
  right: 0; /* Ensure no extra spacing on the right */
  width: 100%; /* Full width */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  transform-origin: bottom right; /* Scale and animate from bottom-right corner */
  z-index: 10; /* Ensure it appears above the report */

  /* Combined animation properties */
  animation: slideInFromBottomWithScale 1.5s ease-out forwards;
  opacity: 0; /* Initially hidden */
}

.phoneImg {
  width: 100%; /* Fill the entire container width */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any inline spacing */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

@media (max-width: 1024px) {
  .container {
    display: none;
  }
}
