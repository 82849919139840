@keyframes slideInFromBottom {
  from {
    transform: translateY(100%); /* Start completely off-screen */
    opacity: 0; /* Start invisible */
  }
  to {
    transform: translateY(0); /* End in the original position */
    opacity: 1; /* Fully visible */
  }
}

.container {
  position: relative; /* Establish positioning context for the phone */
  overflow: hidden; /* Clip content that overflows the container */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end; /* Align horizontally to the end */
  align-items: flex-end; /* Align vertically to the bottom */
}

.report {
  scale: 0.9;
  transform-origin: bottom; /* Scale relative to the bottom edge */
  overflow: auto; /* Allow scrolling inside the report */
  max-height: 100%; /* Ensure it doesn't exceed container bounds */
  margin-bottom: 0; /* No margin pushing it upwards */
  display: flex;
  justify-content: flex-end; /* Align horizontally to the end */
  align-items: flex-end; /* Align vertically to the bottom */

  /* Animation properties */
  animation: slideInFromBottom 0.7s ease-out forwards;
  opacity: 0; /* Initially hidden */
  border-radius: var(--br-base) var(--br-base) 0 0;
  box-shadow:
    0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -1px 8px 2px rgba(10, 37, 64, 0.25);
}

.img {
  flex: 1;
  width: 100%;
  border-radius: var(--br-base) var(--br-base) 0 0;
  box-shadow:
    0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -1px 8px 2px rgba(10, 37, 64, 0.25);
}
