@keyframes fadeIn {
  from {
    opacity: 0; /* Start invisible */
  }
  to {
    opacity: 1; /* Fully visible */
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  align-content: center;
  height: 100%;

  /* Animation properties */
  animation: fadeIn 2s ease-out forwards; /* Duration: 1s, easing: ease-out */
  opacity: 0; /* Start invisible */
}

.content {
  max-width: var(--max-width);
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;
}

.h1 {
  font-size: 64px;
  line-height: 68px;
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--secondary-color);
  letter-spacing: -0.02em;
}

.h2 {
  font-size: 32px;
  line-height: 36px;
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--secondary-color);
}

.h3 {
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
  font-family: var(--font-mono);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.description {
  font-size: 2rem;
  line-height: 28px;
  max-width: 520px;
  color: var(--color-text-light);
  font-family: var(--font-primary);
}

.placeholder {
  background-color: var(--grey-1-color);
  width: 100%;
  height: 100%;
  min-height: 120px;
  border-radius: var(--border-radius);
}

.contentRow {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.col_1 {
  width: 70%;
  flex-direction: column;
  display: flex;
  gap: 24px;
}

.col_2 {
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 16px;
  overflow-x: hidden;
  position: relative;
  padding: 16px 0;
  height: 100%;

  box-shadow:
    inset -10px -10px 15px rgba(255, 255, 255, 0.5),
    inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  background: var(--grey-1-color);
}

.CWLink {
  font-family: var(--font-mono);
  display: flex;
  flex-direction: row;
  gap: 16px;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  color: var(--secondary-color);
  cursor: pointer;
  align-items: center;
}

.CWLinkLabel {
  position: relative;
  font-weight: 300;
  z-index: 100;
}

.CWLinkIcon {
  position: relative;
  width: 2rem;
  height: 1.12rem;
  flex-shrink: 0;
  z-index: 100;
  display: inline;
  fill: var(--secondary-color);
}

.CWLink:hover {
  gap: 24px;
}

.evList {
  width: 200%;
  display: flex;
  /*overflow: hidden;*/
  gap: 16px;
}

.evCard {
  flex: 1;
  padding: 8px;
  min-width: 150px;
  background-color: var(--white-color);
  border-radius: var(--border-radius);
  font-family: var(--font-mono);
  text-transform: uppercase;
  line-height: normal;
  text-align: left;
  color: var(--secondary-color);
  font-size: 12px;

  box-shadow:
    -5px -5px 9px rgba(255, 255, 255, 0.45),
    5px 5px 9px rgba(94, 104, 121, 0.3);
}

.evCardImage {
  background-color: var(--white-color);
  width: 100%;
  height: auto;
  display: block;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.fallbackContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90px;
}

.evCardImageFallback {
  background-color: var(--white-color);
  width: 100%;
  max-width: 50px;
  height: auto;
  display: block;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.leftDetail {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  max-width: 200px;
  width: 15%;
}

.rightDetail {
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 200px;
  width: 15%;
}

/* Add this for infinite scrolling */
@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Mobile */
@media (max-width: 700px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    overflow: hidden;
    padding: 40px 24px;
  }

  .contentRow {
    grid-row-gap: 40px;
    row-gap: 40px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .evCard {
    min-width: 120px;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}
