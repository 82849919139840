.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(100vh - 160px);
  min-height: 680px;
  overflow: hidden;
  padding: 0 80px;
}

.content {
  max-width: var(--max-width);
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;

  padding: var(--base-size-80, 80px) 0;
}

.ev {
  color: var(--blue);
}

.h1 {
  /*font-size: 56px;*/
  /*line-height: 68px;*/
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--secondary-color);
  letter-spacing: -0.02em;

  font-size: var(--medium-1-size);
  line-height: 6rem;
}

.h2 {
  font-size: 32px;
  line-height: 36px;
}

.h3 {
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
  font-family: var(--font-mono);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.description {
  font-size: 2rem;
  line-height: 28px;
  max-width: 520px;
  color: var(--color-text-light);
  font-family: var(--font-primary);
}

.contentRow {
  grid-column-gap: 40px;
  column-gap: 40px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.col_1 {
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 40px;
}

.col_2 {
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;
  min-height: 480px;
  position: relative;
}

.heroImage {
  border-radius: var(--border-radius);
  /*transform: scaleX(-1);*/
}

.heroBtnText {
  position: relative;
  font-weight: 500;
  z-index: 100;
}

.heroBtnIcon {
  position: relative;
  width: 2rem;
  height: 1.12rem;
  flex-shrink: 0;
  z-index: 100;
  display: inline;
}

.heroBtn {
  max-width: 123.75rem;
  width: 100%;
  border-radius: var(--br-81xl);
  background: var(--secondary-color);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
  font-size: var(--regular-6-size);
  color: var(--primary-color);
  transition: all 0.3s ease-in-out;
  font-family: var(--font-primary);

  position: relative;

  /*DIRECT GRADIENT*/
  /*
  background: linear-gradient(var(--secondary-color), var(--secondary-color)) padding-box, linear-gradient( 60deg, var(--purple-color), var(--primary-color), var(--green-1-color), var(--yellow-color) ) border-box;
  border: 4px solid transparent;*/
}

.heroBtn::before /*,
.heroBtn::after */ {
  content: '';
  position: absolute;
  inset: -0.4rem;
  z-index: -1;
  background: linear-gradient(
    60deg,
    /*from var(--gradient-angle),*/ /*WITH CONIC GRADIENT*/ var(--purple-color),
    var(--primary-color),
    var(--green-1-color),
    var(--yellow-color),
    var(--green-1-color),
    var(--primary-color),
    var(--purple-color)
  );
  border-radius: inherit;
  /*animation: rotation 10s linear infinite;*/
  background-clip: border-box;
  box-sizing: border-box;
}

.heroBtn::after {
  /*filter: blur(1.5rem);*/
}

.CWButton {
  padding: 16px 16px;
  justify-content: center;
  border-radius: 50px;
  color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: var(--white-color);
  align-items: center;
  gap: 8px;
  font-family: var(--font-mono);
  text-transform: uppercase;
  box-shadow: -4px 4px 0px 0px var(--secondary-color);
  border: 1px solid var(--secondary-color);
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  line-height: normal;
}

.CWButtonIcon {
  position: relative;
  width: 2rem;
  height: 1.12rem;
  flex-shrink: 0;
  z-index: 100;
  display: inline;
  fill: var(--secondary-color);
}

.CWButtonLabel {
  position: relative;
  font-weight: 300;
  z-index: 100;
}

.CWButton:hover {
  gap: 16px;
  box-shadow: -0px 0px 0px 0px var(--secondary-color);
  transform: translate(-4px, 4px);
}

.first-text {
  color: var(--blue-color);
}

.final-text {
  color: var(--secondary-color);
}

.caret {
  display: inline-block;
  width: 1px;
  background-color: #000; /* Adjust the color as needed */
  animation: blink 1s step-start infinite;
  margin-left: 2px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: 100vh;

    min-height: 1106px;
    overflow: hidden;
    padding: 0 24px;
  }

  .contentRow {
    grid-row-gap: 40px;
    row-gap: 40px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .CWButton {
    padding: 16px 16px;
    font-size: 16px;
    line-height: normal;
  }

  .col_2 {
    min-height: 540px;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .CWButton {
    padding: 16px 32px;
    font-size: 14px;
    line-height: normal;
  }
}
