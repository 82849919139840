.motionDiv {
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--grey-1-color);
  border-radius: var(--border-radius-lg);
  background: linear-gradient(135deg, var(--secondary-color), #4c78ec);
}

.searchContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.searchContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-xxl) 0 var(--spacing-xxl);
}

.searchPage {
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
  background: linear-gradient(
    hsla(0, 0%, 100%, 0.2),
    hsla(0, 0%, 100%, 0.1) 25%,
    rgba(246, 249, 252, 0.1) 60%,
    rgba(246, 249, 252, 0) 100%
  );
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: var(--padding-lg);
}

.searchFormWrapper {
  transform: scale(0.8);
  transform-origin: center center;
  min-width: 400px;
}

.searchForm {
  max-width: 520px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.08);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border: 1px solid hsla(0, 0%, 100%, 0.23);
  /*box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);*/
  box-shadow:
    inset 0 1px 1px 0 hsla(0, 0%, 100%, 0.1),
    0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-lg);
}

.searchBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.searchTitle {
  font-size: var(--font-size-xs);
  color: var(--transparent-white);
  font-family: var(--font-mono);
  text-transform: uppercase;
  text-align: center;
}

.searchInput {
  color: var(--white-color);
  font-family: var(--font-mono);
  font-size: 16px;
  padding: 14px 24px;
  background-color: hsla(0, 0%, 100%, 0.05);
  border: 1px solid #ffffff4d;
  border-radius: 16px;
  line-height: normal;
  text-align: left;
  transition: all 0.5s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  text-transform: uppercase;
}

.searchInput[type='number']::-webkit-inner-spin-button,
.searchInput[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.searchInput[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.searchInput:focus {
  outline: none;
  background-color: hsla(0, 0%, 100%, 0.1);
  border: 1px solid var(--primary-color);
}

.searchInput::placeholder {
  color: var(--transparent-white);
  text-transform: none;
}

.announcement {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: 0 var(--spacing-xl);
}

.announcementTitle {
  font-family: var(--font-primary);
  font-size: var(--font-size-md);
  color: var(--white-color);
  text-align: center;
  line-height: normal;
}

.announcementSubtitle {
  font-family: var(--font-primary);
  font-size: var(--font-size-sm);
  color: var(--primary-color);
  text-align: center;
  line-height: normal;
  max-width: 370px;
}

.logoIcon {
  position: relative;
  width: 80px;
  /*height: 3rem;*/
  flex-shrink: 0;
  /*mix-blend-mode: multiply;*/
  vertical-align: middle;
  opacity: 0.6;
}

.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--padding-lg) var(--padding-xl);
}

.menuBtn {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-sm);
  align-items: center;
  justify-content: center;
  background-color: var(--transparent-black);
  font-family: var(--font-mono);
  text-transform: uppercase;
  border-radius: 50px;
  padding: 6px 10px;
  font-size: var(--font-size-xxs);
  color: var(--white-color);
  line-height: normal;
  opacity: 0.5;
  display: none;
}

.newProduct {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-xs);
  font-family: var(--font-mono);
  color: var(--primary-color);
  text-transform: uppercase;
  position: absolute;
  line-height: normal;
  top: -20px;
  letter-spacing: 1.2px;
  background:
    linear-gradient(var(--secondary-color), var(--secondary-color)) padding-box,
    linear-gradient(60deg, var(--purple-color), var(--primary-color), var(--green-1-color), var(--yellow-color))
      border-box;
  border: 1px solid transparent;
  z-index: 10;
  padding: var(--spacing-md) var(--spacing-xl);
  border-radius: var(--border-radius-xxxl);
}

.evPlateUK {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: var(--border-radius-md);
  box-sizing: border-box;
  /*border: 2px solid var(--secondary-color);*/
  background: linear-gradient(0deg, #ffffffbd, #ffffff);
  max-width: 400px;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.greenElement {
  background: linear-gradient(0deg, #32c85e, var(--green-1-color));
  width: 14%;
}

.plateInputContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-lg);
  padding: var(--spacing-md);
  flex: 1;
}

.plateInput {
  color: var(--secondary-color);
  font-family: var(--font-plate);
  font-size: var(--font-size-3xl);
  border: none;
  text-align: left;
  transition: all 0.5s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  background: none;
  line-height: normal;
}

.plateInput:focus {
  outline: none;
  border: none;
}

.plateInput::placeholder {
  font-family: var(--font-plate);
  font-size: var(--font-size-3xl);
  color: var(--grey-3-color);
  opacity: 0.8;
  letter-spacing: -1px;
  line-height: normal;
}

/* Mobile */
@media (max-width: 700px) {
  .searchFormWrapper {
    min-width: 200px;
    width: 100%;
    transform: scale(0.88);
  }

  .announcementTitle {
    font-size: var(--font-size-sm);
  }

  .announcementSubtitle {
    font-size: var(--font-size-xs);
  }

  .newProduct {
    font-size: var(--font-size-base);
    top: -16px;
  }

  .searchContent {
    padding: var(--spacing-xxl) var(--spacing-lg) 0 var(--spacing-lg);
  }

  .pageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: var(--padding-lg) var(--padding-lg);
  }

  .announcement {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    padding: 0 var(--spacing-lg);
  }

  .searchForm {
    max-width: 620px;
    width: 100%;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

