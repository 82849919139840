.footer {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.footerContent {
  padding: var(--spacing-xxl) 0;
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-lg);
  border-top: 1px solid rgba(255, 255, 255, 0.13);
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);
}

.footerColumn {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
  flex: 1;
  margin-bottom: 0;
}

.columnBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.columnHeader {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.title {
  font-size: var(--font-size-xs);
  font-family: var(--font-mono);
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 1.1px;
}

.description {
  font-size: var(--font-size-xs);
  font-family: var(--font-primary);
  color: var(--primary-color);
  line-height: 1.4;
  opacity: 0.8;
}

.footerLink {
  font-size: var(--font-size-xs);
  font-family: var(--font-primary);
  color: var(--white-color);
  text-decoration: none;
  padding: var(--spacing-xs) 0;
  line-height: 1.4;
}

.footerLink:hover {
  text-decoration: underline;
}

/* Mobile */
@media (max-width: 700px) {

  .footer {
    padding: 0 var(--spacing-lg);
  }


  .footerContent {
    flex-direction: column;
    gap: var(--spacing-xxl);
    padding: var(--spacing-xxl) 0;
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    flex: 1;
    margin-bottom: var(--spacing-xxl);
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}
