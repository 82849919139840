.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 100%;
  align-content: center;
  justify-content: center;
}

.content {
  max-width: var(--max-width);
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;
}

.h1 {
  font-size: 64px;
  line-height: 68px;
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--secondary-color);
  letter-spacing: -0.02em;
}

.h2 {
  font-size: 32px;
  line-height: 36px;
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--secondary-color);
}

.h3 {
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
  font-family: var(--font-mono);
  text-transform: uppercase;
}

.description {
  font-size: 2rem;
  line-height: 28px;
  max-width: 520px;
  color: var(--color-text-light);
  font-family: var(--font-primary);
}

.description_alt {
  font-size: 1.7rem;
  line-height: normal;
  max-width: 520px;
  color: var(--color-text-light);
  font-family: var(--font-primary);
  padding: 16px 0 8px 0;
}

.contentRow {
  flex: 1 1;
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 24px;
  column-gap: 24px;
  grid-row-gap: 24px;
  row-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(min(10rem, 100%), 1fr));
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%); /* Start completely off-screen to the right */
    opacity: 0; /* Start invisible */
  }
  to {
    transform: translateX(0); /* End at its original position */
    opacity: 1; /* Fully visible */
  }
}

.scrollContainer {
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  width: 100%;
  padding: 16px 0px 0px 16px;
  display: flex;
  flex-direction: column;

  /* Animation properties */
  transform: translateX(100%); /* Start off-screen to the right */
  opacity: 0; /* Initially hidden */
  animation: slideInFromRight 0.7s ease-out forwards;
}

.contentRowCards {
  display: grid;
  grid-auto-flow: column; /* Flow horizontally */
  grid-template-rows: repeat(2, 1fr); /* Two rows */
  grid-auto-columns: minmax(220px, 1fr); /* Card width */
  gap: 24px; /* Space between cards */
  width: max-content; /* Ensures grid extends horizontally */
}

.topicCategory {
  box-sizing: border-box;
  flex: 1 1;
  gap: 24px;
  display: flex;
  flex-direction: row;
}

.topicCard,
.topicCardCat1,
.topicCardCat2,
.topicCardCat3,
.topicCardCat4 {
  width: 100%;
  min-width: 220px;
  max-width: 220px;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow:
    -5px -5px 9px rgba(255, 255, 255, 0.45),
    5px 5px 9px rgba(94, 104, 121, 0.3);
  background-color: var(--white-color);
  color: var(--secondary-color);
  gap: 16px;
}

.topicCardCat1 {
  border: 2px solid var(--green-1-color);
}

.topicCardCat2 {
  border: 2px solid var(--primary-color);
}

.topicCardCat3 {
  border: 2px solid var(--purple-color);
}

.topicCardCat4 {
  border: 2px solid var(--magenta-color);
}

.topicCardCat1:hover {
  border-bottom: 16px solid var(--green-1-color);
}

.topicCardCat2:hover {
  border-bottom: 16px solid var(--primary-color);
}

.topicCardCat3:hover {
  border-bottom: 16px solid var(--purple-color);
}

.topicCardCat4:hover {
  border-bottom: 16px solid var(--magenta-color);
}

.topicCard:hover,
.topicCardCat1:hover,
.topicCardCat2:hover,
.topicCardCat3:hover,
.topicCardCat4:hover {
  /*background-color: var(--secondary-color);*/
  /*color: var(--white-color);*/
  color: var(--secondary-color);
  transform: translateY(-5px);
}

.sectionNav {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 16px;
  z-index: 11;
  align-items: center;
}

.buttonNav {
  font-family: var(--font-primary);
  font-size: 16px;
  cursor: pointer;
  padding: 4px 16px;
  color: var(--secondary-color);
  font-weight: 600;
  text-align: center;
}

.pill {
  position: absolute;
  height: 100%;
  border-radius: 100px;
  transition: all 0.4s ease;
  z-index: -1;
}

.cardIllustration {
  width: 56px;
  height: 56px;
  border-radius: 4px;
  /*box-shadow: -4px 4px 0 0 var(--grey-1-color);*/
}

.cardIllustration svg {
  width: 56px;
  height: 56px;
}

.cardTitle {
  font-family: var(--font-mono);
  font-size: var(--base-size-24);
}

.cardAction {
  font-family: var(--font-mono);
  text-transform: uppercase;
  font-size: var(--base-size-24);
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.cardIcon {
  position: relative;
  width: 2rem;
  height: 1.12rem;
  flex-shrink: 0;
  z-index: 100;
  display: inline;
  fill: var(--secondary-color);
}

.cardLabel {
  position: relative;
}

.leftDetail {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  max-width: 200px;
  width: 15%;
  display: None;
}

.rightDetail {
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 200px;
  width: 15%;
}

.scrollAndDetailContainer {
  position: relative;
  display: flex;
  /* other styles as needed */
}

.CWLink {
  font-family: var(--font-mono);
  display: flex;
  flex-direction: row;
  gap: 16px;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  color: var(--secondary-color);
  cursor: pointer;
  align-items: center;
}

.CWLinkLabel {
  position: relative;
  font-weight: 300;
  z-index: 100;
}

.CWLinkIcon {
  position: relative;
  width: 2rem;
  height: 1.12rem;
  flex-shrink: 0;
  z-index: 100;
  display: inline;
  fill: var(--secondary-color);
}

.CWLink:hover {
  gap: 24px;
}

/* Mobile */
@media (max-width: 700px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    overflow: hidden;
    padding: 40px 24px;
  }

  .contentRow {
    grid-row-gap: 40px;
    row-gap: 40px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .buttonNav {
    font-family: var(--font-primary);
    font-size: 14px;
    cursor: pointer;
    padding: 4px 8px;
    line-height: normal;
    text-align: center;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}
