.h1 {
  /*font-size: 56px;*/
  /*line-height: 68px;*/
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--secondary-color);
  letter-spacing: -0.02em;

  font-size: var(--medium-1-size);
  line-height: 6rem;
}

.h3 {
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
  font-family: var(--font-mono);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.h2 {
  font-size: 32px;
  line-height: 36px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0 80px;
}

.content {
  max-width: var(--max-width);
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;

  padding: var(--base-size-80, 80px) 0;
}

.sectionIntroduction {
  /*margin-bottom: 40px;*/
}

/* Three-column layout for desktop */
.sectionContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  height: 100%;
}

.secondColumn {
  display: flex;
  flex-direction: column;
  height: 100%; /* or a fixed height if needed */
  gap: 16px;
}

/* Card Styles */
.cardBlue,
.cardGreen,
.cardPurple,
.cardDarkBlue,
.cardRed {
  border-radius: 8px;
  padding: 20px;
  color: #fff;
  font-family: var(--font-primary);
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.cardBlue:hover,
.cardGreen:hover,
.cardPurple:hover,
.cardDarkBlue:hover,
.cardRed:hover {
  transform: scale(1.02) !important;
  transition: transform 0.3s ease-in-out !important;
}

.cardGreen,
.cardDarkBlue,
.cardRed,
.cardPurple {
  height: 50%;
}

/* Adjust background colors to match your image */
.cardBlue {
  background: linear-gradient(135deg, #3b80f3 0%, #2b5eb7 100%);
}

.cardGreen {
  background: linear-gradient(275deg, #05293d 0%, #29ad71 100%);
}

.cardPurple {
  background: linear-gradient(275deg, #05293d 0%, #9747ff 100%);
}

.cardRed {
  background: linear-gradient(275deg, #ff6666 0%, #720024 100%);
  gap: 8px;
}

.cardDarkBlue {
  background: linear-gradient(135deg, #05293d 00%, #0d6ea3 100%);
}

/* Quote text */
.quote {
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Attribution section */
.attribution {
  border-radius: 8px;
  padding: 20px;
  background: var(--transparent-black);
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
}

.attribution h4 {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
}

.attribution p {
  margin: 5px 0 0 0;
  font-size: 1.4rem;
  font-family: var(--font-mono);
  font-weight: normal;
  color: var(--grey-2-color);
}

/* Logos */
.logoGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.logo {
  max-height: 60px;
  max-width: 50%;
  object-fit: contain;
  filter: invert(1) brightness(3);
}

.logoOneAuto {
  max-height: 60px;
  max-width: 50%;
  object-fit: contain;
}

.ATlogo {
  max-height: 60px;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-self: center;
  object-fit: contain;
  filter: invert(1) brightness(2);
}

.LElogo {
  width: 60%;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-self: center;
  object-fit: contain;
  filter: invert(1) brightness(2);
  flex: 1;
}

/* Adjustments for the bottom logo in the purple card */
.bottomLogo {
  text-align: center;
  filter: grayscale(1) brightness(1000%);
  flex: 1;
}

.mono {
  font-family: var(--font-mono);
  font-size: var(--medium-5-size);
  text-align: center;
  justify-content: center;
  align-content: center;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  flex: 1;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .sectionContent {
    grid-template-columns: 1fr 1fr;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 0 24px;
  }
}

@media (max-width: 600px) {
  .sectionContent {
    grid-template-columns: 1fr;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 0 24px;
  }
}
