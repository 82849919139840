.h3 {
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
  font-family: var(--font-mono);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0 80px;
}

.content {
  max-width: var(--max-width);
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;
  color: var(--white-color);
  padding: var(--base-size-80, 80px) 0;
}

/* Base tab container */
.tabContainer {
  border-radius: 16px;
  padding: 16px;
  overflow: hidden; /* Prevent content overflow during transitions */
  transition: all 1.5s ease; /* Smooth transitions */
  max-height: 200px; /* Starting height for inactive tabs */
  transform: scale(0.98); /* Slightly smaller when inactive */
  opacity: 1;
}

/* Active Tab */
.activeTabContainer {
  background: linear-gradient(135deg, #3b80f3 0%, #2b5eb7 100%);
  max-height: 100%; /* Expand to full height */
  transform: scale(1); /* Full size */
  opacity: 1;
}

.inactiveTabContainer {
  background: linear-gradient(145deg, #0e3346 10%, #244b72 40%, #0b1921 80%);
  max-height: 200px; /* Limited height for inactive state */
  transform: scale(0.95); /* Slightly smaller */
  opacity: 0.7; /* Faded out */
  cursor: pointer;
  position: relative; /* To position the shimmer pseudo-element */
  overflow: hidden; /* Prevent shimmer from spilling out */
}

.inactiveTabContainer:hover {
  scale: 1.05;
}

.inactiveTabContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%; /* Start shimmer off-screen */
  width: 150%; /* Wide enough to cover the container diagonally */
  height: 100%;
  background: linear-gradient(120deg, transparent 30%, rgba(255, 255, 255, 0.2) 50%, transparent 70%);
  animation: shimmer 2s infinite linear; /* Shimmer animation */
}

@keyframes shimmer {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(150%); /* Move shimmer across the container */
  }
}

/* Tab Header: Align indicator and title */
.tabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 1.2s ease; /* Smooth transition for tab title */
}

/* Product List */
.productList {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 2s ease,
    transform 2s ease;
}

.inactiveTabContainer .productList {
  opacity: 0;
  transform: translateY(20px); /* Slide content down */
}

/* Product Item Wrapper */
.productTitleWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Product Content */
.productContent {
  transition: all 0.8s ease;
  opacity: 1;
}

.inactiveTabContainer .productContent {
  opacity: 0;
}

.sectionIntroduction {
  /*margin-bottom: 40px;*/
}

/* Three-column layout for desktop */
.sectionContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  height: 100%;
  font-family: var(--font-mono);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.tabSelector {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  border: 2px solid #0b1921;
  border-radius: 16px;
}

.productSelector {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  border-radius: var(--br-base);
  width: 100%;
}

.productList {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: var(--gap-base);
}

.tabTitle {
  flex: 1;
}

.tabHeader {
  font-size: var(--medium-5-size);
  padding: var(--padding-base);
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.productPreview {
  border-radius: var(--br-base);
  background-color: var(--back-light-color);
  overflow: hidden;
}

.productTitle {
  font-size: var(--medium-5-size);
  flex: 1;
}

.productContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--padding-26xl);
  max-height: 700px;
}

.productDescription {
  font-size: var(--medium-5-size);
  font-family: var(--font-primary);
  text-transform: initial;
  letter-spacing: normal;
  flex: 1;
}

.productPricing {
  background-color: var(--transparent-black);
  border-radius: var(--border-radius-lg);
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  font-size: var(--medium-3-size);
  padding: var(--padding-21xl);
}

.productOfferText {
  font-size: var(--medium-5-size);
  flex: 1;
  padding-bottom: var(--padding-5xl);
}

.productOldPrice {
  text-decoration: line-through;
  font-size: var(--medium-4-size);
  padding-bottom: var(--padding-base);
  opacity: 0.5;
}

.productNewPrice {
  font-size: var(--medium-2-size);
  padding-bottom: var(--padding-5xl);
  color: var(--white);
  display: flex;
  gap: var(--spacing-md);
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.littleText {
  font-size: var(--font-size-xs);
  font-family: var(--font-primary);
  line-height: normal;
  text-transform: none;
  letter-spacing: normal;
}

.discountCode {
  font-size: var(--medium-5-size);
  flex: 1;
  color: var(--primary-color);
  font-family: var(--font-mono);
}

.sampleReportButton {
  margin-bottom: var(--padding-5xl);
}

.checkAnEv {
  font-family: var(--font-mono) !important;
}

.discount {
  font-size: var(--medium-5-size);
  padding-bottom: var(--padding-5xl);
  flex: 1;
  font-family: var(--font-primary);
  text-transform: initial;
  letter-spacing: normal;
}

.productTitleWrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

/* Active Product Item */
.productItemActive {
  background-color: var(--transparent-white);
  border-radius: var(--br-base);
  padding: var(--padding-26xl);
  gap: var(--padding-26xl);
  display: flex;
  flex-direction: column;
  transition:
    background-color 1.5s ease,
    transform 1.5s ease; /* Slow down transitions */
  transform: scale(1); /* Optional: Add a slight scale effect */
  opacity: 1;
}

/* Inactive Product Item */
.productItemInactive {
  background-color: var(--transparent-black);
  border-radius: var(--br-base);
  cursor: pointer;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-left: var(--padding-26xl);
  padding-right: var(--padding-26xl);
  padding-top: var(--padding-base);
  padding-bottom: var(--padding-base);
  transition:
    background-color 1.5s ease,
    transform 1.5s ease; /* Slow down transitions */
  transform: scale(0.96); /* Slightly smaller */
  opacity: 0.8;
}

.productItemInactive:hover {
  transition:
    background-color 1.5s ease,
    transform 1.5s ease; /* Slow down transitions */
  transform: scale(0.98); /* Slightly smaller */
  opacity: 0.9;
}

/* Product Content Transition */
.productContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--padding-26xl);
  transition:
    opacity 1.5s ease,
    transform 1.5s ease; /* Smooth transition for content */
  opacity: 1;
  transform: translateY(0);
}

/* Fade-out content for inactive product */
.productItemInactive .productContent {
  opacity: 0;
  transform: translateY(20px); /* Slide content down slightly */
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .sectionContent {
    grid-template-columns: 1fr 1fr;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 0 24px;
  }
}

@media (max-width: 600px) {
  .sectionContent {
    grid-template-columns: 1fr;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 0 24px;
  }
}
