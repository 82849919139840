.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  overflow: hidden;
  padding: 80px;
}

.content {
  max-width: var(--max-width);
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 56px;
  padding: var(--base-size-80, 80px) 0;
}


.h1 {
  font-size: 64px;
  line-height: 68px;
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--secondary-color);
  letter-spacing: -0.02em;

}

.h2 {
  font-size: 32px;
  line-height: 36px;
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--secondary-color);
  max-width: 520px;
}


.h3 {
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
  font-family: var(--font-mono);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}


.description {
  font-size: 2rem;
  line-height: 28px;
  max-width: 520px;
  color: var(--color-text-light);
  font-family: var(--font-primary);
}

.contentRowTwo {
  grid-column-gap: 24px;
  column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contentRow {
  grid-column-gap: 32px;
  column-gap: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.placeholder {
  background-color: var(--grey-1-color);
  width: 100%;
  height: 100%;
  min-height: 320px;
  border-radius: var(--border-radius);
}

.col_1 {
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;
}

.col_2 {
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;
}

.col_3 {
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: var(--medium-4-size);
  color: var(--secondary-color);
  font-family: var(--medium-7);
  background-color: var(--white-color);
}

.illustration {
  position: relative;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.cwIllustration {
  width: 70%;
  max-width: 25rem;
  height: auto;
}

.cardTitle {
  line-height: 3.33rem;
  font-weight: 600;
}

.cardText {
  font-size: var(--regular-6-size);
  line-height: var(--line-height-normal);
  color: var(--color-text-light);
}

/* Mobile */
@media (max-width: 700px) {

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    overflow: hidden;
    padding: 40px 24px;
  }

  .contentRowTwo {
    grid-template-columns: 1fr;
  }

  .contentRow {
    grid-row-gap: 80px;
    row-gap: 80px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .cwIllustration {
    width: 80%;
    max-width: 30rem;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {

}
