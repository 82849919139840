@keyframes slideInFromBottom {
  from {
    transform: translateY(600px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding-top: 40%;
  overflow: hidden;
  align-items: center;
}

@media (max-width: 1024px) {
  .container {
    display: none;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  width: 80%;
  background-color: rgb(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border: 1px solid hsla(0, 0%, 100%, 0.23);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

  padding: var(--spacing-xxl);
  border-radius: var(--border-radius-lg);

  animation: slideInFromBottom 1.5s ease-out forwards;
}

.title {
  font-size: var(--font-size-md);
  font-family: var(--font-primary);
  color: var(--secondary-color);
  text-transform: none;
  text-align: center;
  letter-spacing: -0.01rem;
  margin-bottom: var(--spacing-xl);
}

.subtitle {
  font-size: var(--font-size-xs);
  color: var(--white-color);
  font-family: var(--font-mono);
  text-transform: uppercase;
  text-align: center;
}

.error {
  font-size: 10px;
  color: var(--orange-color);
  justify-self: flex-start;
  padding-left: var(--spacing-xl);
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.input {
  color: var(--white-color);
  font-family: var(--font-mono);
  font-size: 1.8rem;
  padding: 16px 24px;
  background-color: hsla(0, 0%, 100%, 0.05);
  border: 1px solid #ffffff4d;
  border-radius: 16px;
  text-align: left;
  transition: all 0.5s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input:focus {
  outline: none;
  background-color: hsla(0, 0%, 100%, 0.1);
  border: 1px solid var(--primary-color);
}

.input::placeholder {
  color: var(--transparent-white);
}

.snackbarContainer {
  width: 100%;
  max-width: 600px;
}

.evPlateUKContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: var(--spacing-md);
}

.evPlateUK {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: var(--border-radius-md);
  box-sizing: border-box;
  /*border: 2px solid var(--secondary-color);*/
  background: linear-gradient(0deg, #ffffffbd, #ffffff);
  max-width: 400px;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.greenElement {
  background: linear-gradient(0deg, #32c85e, var(--green-1-color));
  width: 14%;
}

.plateInputContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-lg);
  padding: var(--spacing-md);
  flex: 1;
}

.plateInput {
  color: var(--secondary-color);
  font-family: var(--font-plate);
  font-size: var(--font-size-3xl);
  border: none;
  text-align: left;
  transition: all 0.5s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  background: none;
  line-height: normal;
}

.plateInput:focus {
  outline: none;
  border: none;
}

.plateInput::placeholder {
  font-family: var(--font-plate);
  font-size: var(--font-size-3xl);
  color: var(--grey-3-color);
  opacity: 0.8;
  letter-spacing: -1px;
  line-height: normal;
}

/* Mobile */
@media (max-width: 700px) {
  .title {
    font-size: var(--font-size-lg);
    color: var(--white-color);
    text-align: center;
    line-height: 1.4;
    margin-bottom: var(--spacing-xl);
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

.report {
  position: absolute;
  bottom: 0;
  top: 350px;
  left: 0;
  right: 0;
  scale: 0.9;
  transform-origin: bottom;
  overflow: hidden;
  object-fit: contain;

  animation: slideInFromBottom 2s ease-out forwards;
  opacity: 1;
  border-radius: var(--br-base) var(--br-base) 0 0;
  box-shadow:
    0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -1px 8px 2px rgba(10, 37, 64, 0.25);
}

.img {
  width: 100%;
  border-radius: var(--br-base);
}

.sampleReportButton {
  width: 80%;
  position: relative;
  opacity: 0;
  animation: slideInFromBottom 1.5s ease-out forwards;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.blobContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;
}
