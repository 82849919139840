.sectionDescription,
.sectionSubtitle {
  position: relative;
  display: inline-block;
  max-width: 53.17rem;
  width: 100%;
}

.sectionSubtitle {
  line-height: normal;
  font-weight: 300;
  font-size: var(--font-size-lg);
}

.sectionDescription {
  font-size: var(--font-size-xs);
  line-height: 1.4;
}

.sectionIntroduction {
  max-width: 112.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  /*padding: var(--padding-3xs);*/
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}

.placeholder,
.text {
  position: relative;
  line-height: var(--line-height-normal);
}

.placeholder {
  flex: 1;
  font-weight: 500;
  opacity: 0.7;
}

.text {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.75rem;
  flex-shrink: 0;
}

.buttonSubscribe,
.inputControl {
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.buttonSubscribe {
  background-color: var(--primary);
  width: 16.42rem;
  padding: var(--padding-base);
  text-align: center;
  color: var(--secondary);
  opacity: 1;

  font-weight: 600;
  font-size: var(--regular-6-size);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  line-height: var(--line-height-normal);
  border: none;
  cursor: pointer;

  font-family: var(--font-primary);

  transition: all 0.5s ease;
}

.buttonSubscribe:disabled,
.buttonSubscribe[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.inputControl {
  background-color: hsla(0, 0%, 100%, 0.05);
  border: 1px solid #ffffff4d;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-5xs) var(--padding-5xl);
  gap: var(--gap-5xs);
}

.newsletterSubscription {
  position: relative;
  max-width: 49.17rem;
  width: 100%;
  flex-shrink: 0;
  font-size: var(--regular-6-size);
}

.buttonContainer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-lg);
}

.sectionDescriptionAlt {
  font-size: var(--font-size-xs);
  font-family: var(--font-mono);
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 1.1px;
  text-align: left;
}

.sectionContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-29xl);

  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  max-width: var(--max-width);

  padding-bottom: var(--spacing-xl);
}

.section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 0 0 var(--spacing-xxl) 0;
  max-width: var(--max-width);
}

.lineContent,
.newsletter {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--padding-45xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.newsletter {
  /*background-color: var(--secondary);*/

  /*background-image: radial-gradient(#0e3346 20%, var(--secondary) 20%);
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 10px 10px;*/

  padding: var(--spacing-3xl) var(--spacing-lg) 0;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--medium-2-size);
  color: var(--primary);
  font-family: var(--medium-7);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.input_nl {
  appearance: none;
  outline: none;
  border: none;
  font-family: var(--font-primary);
  font-size: var(--fontSizes-xs);
  line-height: var(--lineHeights-xs);
  letter-spacing: var(--letterSpacings-tightS);
  font-weight: var(--fontWeights-regular);
  width: 100%;
  padding: var(--space-16) var(--space-20);
  color: var(--primary-color);
  background-color: transparent;
}

.input_nl::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--primary-color);
  opacity: 0.6; /* Firefox */
}

.input_nl:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--primary-color);
  opacity: 0.6;
}

.input_nl::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--primary-color);
  opacity: 0.6;
}

.stateError {
  padding-top: 8px;
  /*
  margin-top: 8px;
  color: var(--white-color);
  background-color: var(--coral-color);
  padding: var(--padding-5xs) var(--padding-xl);*/
  color: var(--coral-color);
  font-size: var(--medium-7-size);
  border-radius: var(--border-radius);
  font-family: var(--font-mono);
  text-transform: uppercase;
}

.stateSuccess {
  padding-top: 8px;
  /*
  margin-top: 8px;
  color: var(--secondary-color);
  background-color: var(--green-1-color);
  padding: var(--padding-5xs) var(--padding-xl);*/
  color: var(--green-1-color);
  font-size: var(--medium-7-size);
  border-radius: var(--border-radius);
  font-family: var(--font-mono);
  text-transform: uppercase;
}

/* Mobile */
@media (max-width: 700px) {
  .newsletter {
    padding: var(--spacing-2xl) var(--spacing-lg) 0;
  }

  .newsletterSubscription {
    max-width: 100%;
    width: 100%;
  }

  .section {
    flex-direction: column;
    padding: 0 0 var(--spacing-xxl) 0;
  }

  .sectionSubtitle {
    line-height: normal;
    font-weight: 300;
    font-size: var(--font-size-md);
  }

  .sectionDescription {
    font-size: var(--font-size-xs);
    line-height: 1.3;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .newsletterSubscription {
    max-width: 49.17rem;
    width: 100%;
  }
}
