.marqueeWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.marqueeInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 32px;
}
