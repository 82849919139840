.searchInput {
  /*background-color: var(--white-color);
  border-color: var(--white-color);*/
  border-radius: 40px;
 /*border-style: solid;*/
  /*border-width: 3px;*/
  background: linear-gradient(var(--white-color), var(--white-color)) padding-box,
  linear-gradient(60deg, var(--purple-color), var(--primary-color)) border-box;
  border: 3px solid transparent;

  padding: 16px 120px 16px 64px;
  width: 100%;
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 1.8rem;
  color: var(--secondary-color);
  position: relative;
  z-index: 3;
  /*border: 3px solid transparent;*/
}

.searchInput:focus-visible {
  outline: none;
  background: linear-gradient(var(--white-color), var(--white-color)) padding-box,
  linear-gradient(60deg, var(--purple-color), var(--primary-color)) border-box;
  border: 3px solid transparent;
  border-bottom: 0;
  border-radius: 29px 29px 0 0;
  box-shadow: none;
}

.inputContainer {
  position: relative;
}

.searchIcon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.2;
  z-index: 4;
}

.loader {
  /*
  position: absolute;
  right: 24px;
  top: calc(50% + 4px);
  transform: translateY(-50%);
  z-index: 4;
*/

  transition: all 0.2ms;
  background-color: var(--white-color);
  border: none;
  padding: 3px 24px;
  line-height: normal;
  border-radius: 24px;
  font-family: var(--font-primary);
  color: var(--secondary-color);
  cursor: pointer;

  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;

  min-width: 84px;
  text-align: center;
  border: 1px solid var(--grey-1-color);
}

.loaderIcon {
  width: 24px;
  height: 24px;
  opacity: 0.2;
  animation: spin 1s linear infinite;
}

.searchContainer {
  position: relative;
  width: 100%;
}

.searchContainerFocused {
  position: relative;
  width: 100%;
  z-index: 999;
}

.searchContainerBlurred {
  position: relative;
  width: 100%;
  z-index: 9;
}


.instructionsContainer {
  background-color: var(--white-color);
  padding: 14px 24px;
  border-radius: 0 0 8px 8px;
  transition: all ease 0.2ms;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  left: 0;
  position: absolute;
  top: calc(100% - 3px);
  width: 100%;
  z-index: 2;
  color: var(--white-color);
  font-family: var(--font-mono);
  font-size: 1.5rem;

  background: linear-gradient(60deg, var(--purple-color), var(--primary-color)) border-box;
  border: 3px solid transparent;
}

.nothingContainer {
  background-color: var(--white-color);
  padding: 14px 24px;
  border-radius: 0 0 8px 8px;
  transition: all ease 0.2ms;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  left: 0;
  position: absolute;
  top: calc(100% - 3px);
  width: 100%;
  z-index: 2;
  color: var(--white-color);
  font-family: var(--font-mono);
  font-size: 1.5rem;

  background: linear-gradient(60deg, var(--purple-color), var(--primary-color)) border-box;
  border: 3px solid transparent;
}

.nothingContainer,
.searchResultsContainer {
}

.nothingContainer.active,
.searchResultsContainer.active {
}

.searchResultsContainer {
  background-color: var(--white-color);
  padding: 0 0 0 0;
  border-radius: 0 0 24px 24px;

  /*border-bottom: 3px solid transparent;*/
  /*border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;*/
  /*border-left: 3px solid transparent;
  border-right: 3px solid transparent;*/

  background: linear-gradient(var(--white-color), var(--white-color)) padding-box,
  linear-gradient(60deg, var(--purple-color), var(--primary-color)) border-box;
  border: 3px solid transparent;
  border-top: 0;

  /*box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.1);*/
  left: 0;
  max-height: 460px;
  overflow-y: auto;
  position: absolute;
  top: calc(100% - 3px);
  width: 100%;
  z-index: 2;
  transition: all ease 0.2ms;
}

.categoryTitle {
  font-family: var(--font-mono);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 1.5rem;
  color: var(--secondary-color);
  padding-bottom: 8px;
  padding: 8px 24px;
  background-color: var(--grey-1-color);
}

.resultsList {
  list-style: none;
  margin-bottom: 0;
}

.resultsList li {
  padding: 8px 40px;
  font-family: var(--font-primary);
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--secondary-color);
  cursor: pointer;
}

.resultsList li:hover {
  color: var(--white-color);
  background-color: var(--secondary-color);
}

.resultsList li:hover .countText {
  color: var(--primary-color);
}

.makeText {
}

.modelText {
}

.countText {
  margin-left: 8px;
  font-weight: normal;
}

.clearButton {
  transition: all 0.2ms;
  background-color: var(--white-color);
  border: none;
  padding: 8px 24px;
  line-height: normal;
  border-radius: 24px;
  font-family: var(--font-primary);
  color: var(--secondary-color);
  cursor: pointer;

  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  border: 1px solid var(--grey-1-color);

  min-width: 84px;
  text-align: center;
}

.clearButton:hover {
  box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45), 5px 5px 9px rgba(94, 104, 121, 0.3);
  background-color: var(--white-color);
  border: 1px solid var(--grey-1-color);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(5 41 61 / 50%);
  z-index: 999;
  backdrop-filter: blur(8px);
  animation: blurFadeIn 0.3s ease-out;
}

.overlayHidden {
  display: none;
}

.highlight {
  background-color: var(--primary-color);
}

.resultsList li:hover .highlight {
  color: var(--secondary-color);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blurFadeIn {
  from {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(8px);
  }
}
