.appStoreButtonsContainer {
  display: flex;
  gap: var(--gap-5xl); /* Controls spacing between buttons */
  align-items: center; /* Aligns buttons vertically */
  align-content: center;
  width: 100%; /* Full-width container */
  margin: 0 auto; /* Centers the container */
  box-sizing: border-box; /* Ensures padding is included in width/height calculations */
  background-color: transparent;
}

.positionLeft {
  justify-content: flex-start; /* Aligns buttons to the left */
}

.positionCenter {
  justify-content: center; /* Aligns buttons to the center */
}


.appStoreIcon,
.googlePlayIcon {
  width: auto;
  height: 64px; /* Maintains aspect ratio */
  cursor: pointer;
  transition: transform 0.3s ease,
  box-shadow 0.3s ease; /* Adds scaling animation */
  display: block; /* Ensures proper rendering for SVGs inside <img> tags */
  /*box-shadow:
    0 8px 15px var(--secondary-color),
    0 0 20px var(--transparent-black);*/ /* Glow effect */
  border-radius: 8px; /* Matches the rounded corners of your SVGs */
  background-color: hsla(0, 0%, 100%, 0.08);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border: 1px solid hsla(0, 0%, 100%, 0.23);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}

.appStoreIcon:hover,
.googlePlayIcon:hover {
  transform: scale(1.02); /* Slightly enlarges buttons on hover */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Glow effect */
}

@media (max-width: 700px) {
  .appStoreButtonsContainer {
    flex-direction: column;
  }

  .appStoreIcon,
  .googlePlayIcon {
    height: auto; /* Maintains aspect ratio */
    max-width: 250px;
    width: 150px;
  }
}
