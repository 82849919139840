.footer {
  display: flex;
  flex-direction: column;
  gap: 0;
  background: linear-gradient(135deg, var(--secondary-color), #264c89);
  padding: 0 0 var(--spacing-xxl) 0;
}

.copyright {
  font-size: var(--font-size-base);
  font-family: var(--font-primary);
  color: var(--primary-color);
  z-index: 0;
}

.footerButtons {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xl);
  align-items: center;
  justify-content: center;
}

.footerRow {
  display: flex;
  flex-direction: row;;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: var(--spacing-xl);
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  width: 100%;
  max-width: var(--max-width);
  padding: 0;
}

.footer2 {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-xl);
}

.footerLink {
  font-size: var(--font-size-base);
  font-family: var(--font-primary);
  color: var(--white-color);
  text-decoration: none;
  padding: var(--spacing-xs) 0;
  line-height: 1.4;
}

.footerLink:hover {
  text-decoration: underline;
}

.description {
  font-size: var(--font-size-base);
  font-family: var(--font-primary);
  color: var(--primary-color);
}


/* Mobile */
@media (max-width: 700px) {
  .footer2 {
    padding: var(--spacing-xxl) var(--spacing-lg);
  }


  .footerRow {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-xl);
    padding: 0;
  }

  .footerButtons {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl);
  }


}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {

}
