.indicator {
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #00ff00; /* or use your chosen colour */
  overflow: visible; /* allows wave to expand beyond the main element's box */
}

.indicator::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  /* Start the wave at the centre */
  transform: translate(-50%, -50%) scale(0.1);
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: currentColor; /* Match parent's color property */
  opacity: 0.5;
  animation: ripple 1.5s infinite ease-out;
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0.5;
  }
  70% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
