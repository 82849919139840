.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) var(--padding-base);
  gap: var(--gap-base);
  width: 100%;
  height: auto;
  border-radius: var(--br-81xl);
  cursor: pointer;
  font-weight: 100;
  font-size: var(--base-size-24);
  font-family: var(--font-mono);
  justify-content: center;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.icon_left {
  margin-right: 8px;
}

.icon_right {
  margin-left: 8px;
}

.button:hover {
}

/* Variants */
.default {
  background-color: var(--transparent-black);
  color: var(--white-color);
  border: none;
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
}

.default:hover {
  background-color: var(--transparent-white);
}

.ghost {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
  border: 1px solid rgb(255, 255, 255, 0.35);
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  opacity: 0.5;
}

.ghost:hover {
  background-color: var(--transparent-white);
  opacity: 0.8;
}

.secondary {
  background-color: #6c757d;
  color: #fff;
}

.secondary:hover {
  background-color: #5a6268;
}

.danger {
  background-color: #dc3545;
  color: #fff;
}

.danger:hover {
  background-color: #c82333;
}
