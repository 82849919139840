.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  overflow: hidden;
  padding: 80px;
}

.content {
  max-width: var(--max-width);
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 56px;
  padding: var(--base-size-80, 80px) 0;
}

.h3 {
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
  font-family: var(--font-mono);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.contentRow {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: space-between;
}

.card {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: var(--medium-4-size);
  color: var(--secondary-color);
  font-family: var(--medium-7);
  background-color: var(--white-color);
  min-width: 200px;
}

.illustration {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cwIllustration {
  width: 160px;
  max-width: 160px;
  max-height: 80px;
  opacity: 0.8;
}

.gradientLeft,
.gradientRight {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 56px;
  pointer-events: none;
  z-index: 1;
}

.gradientLeft {
  left: 0;
  background: linear-gradient(to right, white, transparent);
}

.gradientRight {
  right: 0;
  background: linear-gradient(to left, white, transparent);
}

/* Mobile */
@media (max-width: 700px) {

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    overflow: hidden;
    padding: 40px 24px;
  }

  .contentRow {
    grid-row-gap: 80px;
    row-gap: 80px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .cwIllustration {
    width: 80%;
    max-width: 30rem;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {

}
